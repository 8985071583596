import React, { Component } from 'react';
import { Entity, Scene } from 'aframe-react';

var blueBallX;
var blueBallY;
var blueBallZ = -3;

var redBallX;
var redBallY;
var redBallZ = -3;

var blueBallPos = "";
var redBallPos = "";

var speed = 0.2;
var mistakes = 0;
var conn;
var score = 0;
var rand;
var mountFlag = false;

var recievedCommand = false;

class Blue extends Component {
  constructor() {
    super()
  }

  componentDidMount(){
    if (!mountFlag){
    setTimeout(this.newConn, 500);
    setInterval(this.ball, 250);
    mountFlag = true;
  }
  }

  ball(){
    if (recievedCommand){
    if(blueBallZ == -3){
      console.log("Blue Z: " + blueBallZ)
        blueBallX = 0.5; 
        blueBallY = 1.6; 
        blueBallZ = -3;
        var blueInterval = setInterval(function() {
        blueBallZ += speed;
        blueBallPos = blueBallX.toString() + " " + blueBallY.toString() +  " " + blueBallZ.toString();
      
        var ballPosti = document.getElementById("blueBall")
        ballPosti.setAttribute("position", blueBallPos);

        if (blueBallZ >= 0.5){ 
          clearInterval(blueInterval)
          blueBallZ = -3;
          blueBallPos = blueBallX.toString() + " -2 -3";
          mistakes++;
          var ballPosti = document.getElementById("blueBall")
          ballPosti.setAttribute("position", blueBallPos);
        }
      }, 200);
    }
}
}


  newConn(){

    function endTrain() {
      recievedCommand = false;
      console.log("endInt")
      if(conn !== null){
        if (conn.readyState === WebSocket.OPEN) {
          conn.send(JSON.stringify({type: "key", name: "eegapp", key: "endInt"}));
        }
      }
    }

    function sendTrain() {

      console.log("sendTrain")
      recievedCommand = true;

      if(conn !== null){
        if (conn.readyState === WebSocket.OPEN) {
          conn.send(JSON.stringify({type: "key", name: "eegapp", key: "trainTime"}));
        }
      }
      setTimeout(endTrain, 60000);
    }

    console.log("newConn");
    conn = new WebSocket("wss://signal-dot-ks-ai-367818.ue.r.appspot.com/");
    conn.onmessage = function (msg) {
      var data = JSON.parse(msg.data);
      if(data.type === "connected"){
      data = JSON.parse(msg.data);
    }
    switch(data.type) {
      case "connected":
        console.log("Connected");
        this.send(JSON.stringify({type: "login",name: "vrapp"}));
        break;
      case "key":
        console.log(data.key)
         
    switch( data.key ) {

      case "redTrain":
        this.send(JSON.stringify({type: "key", name: "eegapp", key: "tenSecRest"}));
        console.log("tenSecRest")
        setTimeout(sendTrain, 10000)       
        break;
      default: 
          break;
  }
    }
    }

  }

  send(message) {
    if(conn !== null){
      if (conn.readyState === WebSocket.OPEN) {
        conn.send(JSON.stringify(message));
      }
    }
  };

  websiteRed() {window.location.href = "red";}
  websiteBlack() {window.location.href = "/";}
  websiteBlue() {window.location.href = "blue";}

  render() {
    return (
      <Scene id="vrCanvas" style="position: absolute;">
          <a-assets>
            <img crossOrigin id="groundTexture" src="https://cdn.aframe.io/a-painter/images/floor.jpg" />
            <img crossOrigin id="skyTexture" src="https://cdn.aframe.io/a-painter/images/sky.jpg" />
          </a-assets>

          <Entity
            primitive="a-plane"
            src="#groundTexture"
            rotation="-90 0 0"
            height="100"
            width="100" />
          <Entity primitive="a-light" type="ambient" color="#445451" />
          <Entity
            primitive="a-light"
            type="point"
            intensity="2"
            position="2 4 4" />
          <Entity
            primitive="a-sky"
            height="2048"
            radius="30"
            src="#skyTexture"
            theta-length="90"
            width="2048" />
          <Entity particle-system={{ preset: 'snow', particleCount: 2000 }} />

          <Entity
            text={{ value: 'Welcome!', align: 'center' }}
            position={{ x: 0, y: 1.9, z: -1 }} />
          <Entity
            text={{ value: '', align: 'center' }}
            position={{ x: 0, y: 1.7, z: -1 }} 
            id="score"/>
          <Entity
            text={{ value: '', align: 'center' }}
            position={{ x: 0, y: 1.6, z: -1 }} 
            id="mistakes"/>
          <Entity geometry={{primitive: 'sphere', radius: 0.1}}  position={{x: 0.01, y: -2, z: -3}} id="blueBall" material={{color: 'blue'}}/>
     
          <Entity id="red"
          geometry={{primitive: 'box'}}
          material={{color: "red"}}
          position={{x: -2, y: 4, z: -4}}
          events={{click: this.websiteRed}}/>

          <Entity id="black"
          geometry={{primitive: 'box'}}
          material={{color: "purple"}}
          position={{x: 0, y: 4, z: -4}}
          events={{click: this.websiteBlack}}/>

          <Entity id="blue"
          geometry={{primitive: 'box'}}
          material={{color: "blue"}}
          position={{x: 2, y: 4, z: -4}}
          events={{click: this.websiteBlue}}/>

          <Entity primitive="a-camera" 
                  position={{x: 0, y: 1.6, z: 0 }}
                  keyboard-controls
                  id="cont"
          >
            <Entity
              primitive="a-cursor"
              animation__click={{
                property: 'scale',
                startEvents: 'click',
                from: '0.1 0.1 0.1',
                to: '1 1 1',
                dur: 150
              }} />
          </Entity>
        </Scene>
    )
  }
}

export default Blue