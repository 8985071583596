import "./App.css";
// importing components from react-router-dom package
import {
BrowserRouter as Router,
Routes,
Route,
Navigate,
} from "react-router-dom";

// import Home component
import Home from "./training/Home";
// import About component
import Blue from "./training/blue";
// import ContactUs component
import Red from "./training/red";

function App() {
return (
	<>
	{/* This is the alias of BrowserRouter i.e. Router */}
	<Router>
		<Routes>
		{/* This route is for home component
		with exact path "/", in component props
		we passes the imported component*/}
		<Route index element={<Home />} />
			
		{/* This route is for about component
		with exact path "/about", in component
		props we passes the imported component*/}
		<Route path="/blue" element={<Blue />} />
			
		{/* This route is for contactus component
		with exact path "/contactus", in
		component props we passes the imported component*/}
		<Route path="/red" element={<Red />} />
			
		{/* If any route mismatches the upper
		route endpoints then, Navigate triggers
		and Navigates app to home component with to="/"   
    <Navigate to="/" /> */}
		</Routes>
	</Router>
	</>
);
}

export default App;
